"use strict";

/*global
 window, document, console, require, Foundation
 */

import 'lib/foundation-modules';


document.addEventListener('DOMContentLoaded', () => {
    $(document).foundation();


    /*
	var lastScrollTop = 100;
	$(window).scroll(function(event){
		var st = $(this).scrollTop();
		if (st > lastScrollTop){
			$('nav').removeClass('visible').addClass('hidden');
			console.log('hidden');
		} else {
			$('nav').removeClass('hidden').addClass('visible');
			console.log('visible');
		}
		lastScrollTop = st + 50;
	});
     */





	// NAV SCROLLER

	var loggedPos = 0;

	var prevScrollpos = window.pageYOffset;
	window.onscroll = function() {
		var currentScrollPos = window.pageYOffset;

		loggedPos = (prevScrollpos - 30);

		if (prevScrollpos > currentScrollPos) {

			if (prevScrollpos > currentScrollPos) {
				if (currentScrollPos < loggedPos) {
					$('nav').removeClass('hidden').addClass('visible');
				}
			}

		} else {
			if(prevScrollpos < 200 ) {
				// if scrolld less than 200px from top, show always.
				$('nav').removeClass('hidden').addClass('visible');
			} else {
				$('nav').removeClass('visible').addClass('hidden');
			}
		}

		prevScrollpos = currentScrollPos;

	};
























/*
	$('.nav-icon').click(function(){
		if($(this).hasClass('open')){
			$(this).removeClass('open');
			$('.menu').removeClass('visible');
			$('nav').removeClass('height100');
		} else {
			$(this).addClass('open');
			$('.menu').addClass('visible');
			$('nav').addClass('height100');
		}
	});

	function hideNav() {
		//$('nav').addClass('hidden').removeClass('visible');
		$('.nav-icon').removeClass('open');
		$('.menu').removeClass('visible');
	}
	function showNav() {
		$('nav').addClass('visible').removeClass('hidden');
	}
	$(document).click(function(e){
		if ($(e.target).closest("nav").length === 0) {
			// .closest can help you determine if the element
			// or one of its ancestors is #menuscontainer
			console.log('off click');
			hideNav();
		}
	});
	*/


	/*
	$('.column').hover(function () {
		$('.column').removeClass('scale');
		$(this).addClass('scale');
	});
	 */

	// body onload
	$('.front-info').removeClass('hideaway');
	$('.front-triangle').removeClass('hideaway');

	$(window).scroll(function ()
	{
		var content_height = $(document).height();
		var content_scroll_pos = $(window).scrollTop();
		var percentage_value = content_scroll_pos * 100 / content_height;

		if(percentage_value >= 0.5) {
			$('.front-info').addClass('hideaway');
			$('.front-triangle').addClass('hideaway');
		} else {
			$('.front-info').removeClass('hideaway');
			$('.front-triangle').removeClass('hideaway');
		}

		if(percentage_value >= 0.5) {
			$('.infobox').addClass('hideaway');
		} else {
			$('.infobox').removeClass('hideaway');
		}

	});



	// frontpage hero slider
	$('.hero-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 8000,
		dots: false,
		pauseOnDotsHover: true,
		arrows: false,
	});

	// frontpage scroller indicator
	$(window).scroll(function(){

		if($(this).scrollTop() < 50) {
			$('.scroller-indicator').removeClass('scrolled');
		} else {
			$('.scroller-indicator').addClass('scrolled');
		}

	});







(function(factory){if(typeof define==='function'&&define.amd){define(['jquery'],factory);}else{factory(jQuery);}}(function($){var pluses=/\+/g;function encode(s){return config.raw?s:encodeURIComponent(s);}
	function decode(s){return config.raw?s:decodeURIComponent(s);}
	function stringifyCookieValue(value){return encode(config.json?JSON.stringify(value):String(value));}
	function parseCookieValue(s){if(s.indexOf('"')===0){s=s.slice(1,-1).replace(/\\"/g,'"').replace(/\\\\/g,'\\');}
		try{s=decodeURIComponent(s.replace(pluses,' '));return config.json?JSON.parse(s):s;}catch(e){}}
	function read(s,converter){var value=config.raw?s:parseCookieValue(s);return $.isFunction(converter)?converter(value):value;}
	var config=$.cookie=function(key,value,options){if(value!==undefined&&!$.isFunction(value)){options=$.extend({},config.defaults,options);if(typeof options.expires==='number'){var days=options.expires,t=options.expires=new Date();t.setTime(+t+days*864e+5);}
		return(document.cookie=[encode(key),'=',stringifyCookieValue(value),options.expires?'; expires='+options.expires.toUTCString():'',options.path?'; path='+options.path:'',options.domain?'; domain='+options.domain:'',options.secure?'; secure':''].join(''));}
		var result=key?undefined:{};var cookies=document.cookie?document.cookie.split('; '):[];for(var i=0,l=cookies.length;i<l;i++){var parts=cookies[i].split('=');var name=decode(parts.shift());var cookie=parts.join('=');if(key&&key===name){result=read(cookie,value);break;}
			if(!key&&(cookie=read(cookie))!==undefined){result[name]=cookie;}}
		return result;};config.defaults={};$.removeCookie=function(key,options){if($.cookie(key)===undefined){return false;}
		$.cookie(key,'',$.extend({},options,{expires:-1}));return!$.cookie(key);};}));


	var date = new Date();
	var minutes = 1440;
	date.setTime(date.getTime() + (minutes * 60 * 1000));

	// COOKIES
	// if the cookie is true, hide the initial message and show the other one
	if ($.cookie('hide-after-load') == 'yes') {
		$('#animation').removeClass('hide--second');
		$('#animation').addClass('hide--first');
		$('#animation').remove();
	}

	// add cookie to hide the first message after load (on refresh it will be hidden)
	$.cookie('hide-after-load', 'yes', {expires: date });




	$("p,h1,h2,h3,h4,h5,h6,li,dd,dt,a").each(function(){
		var $this = $(this);
		$this.html($this.html().replace(/(<sup>)?(&reg;|®)(<\/sup>)?/gi, '<sup>®</sup>'));
	});

	var bricklayer = new Bricklayer(document.querySelector('.bricklayer'));
	// bricklayer.redraw();



}); // file end


